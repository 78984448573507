<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Prelab 5 - Question 1</h2>

      <p class="mb-n3">
        a) The ideal method for determining the rate of the reaction between hydrogen peroxide and
        catalase (or potassium iodide) in our laboratory setting is:
      </p>

      <v-radio-group v-model="inputs.idealMethod" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsIdealMethod"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) In a reaction that goes to completion in about 10 min, at what time in the reaction
        should you be measuring the rates in order to be able to appropriately use the method of
        initial rates?
      </p>

      <v-radio-group v-model="inputs.timeFrame" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsTimeFrame"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UOttawaNewPrelab5Q1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        idealMethod: null,
        timeFrame: null,
      },
      optionsIdealMethod: [
        {
          text: 'By doing the reaction over and over and changing different variables',
          value: 'changeDifferentVars',
        },
        {
          text: 'By holding one of the reactant’s concentration constant',
          value: 'oneReactConstant',
        },
        {
          text: 'By holding the catalase/KI initial concentration constant and varying the $\\ce{[H2O2]}$ and applying the method of initial rates',
          value: 'varyH2O2',
        },
        {
          text: 'By holding the $\\ce{[H2O2]}$ initial concentration constant and varying the catalase/KI concentration and applying the method of initial rates',
          value: 'varyCatalyst',
        },
        {
          text: 'By holding the catalase/KI concentration constant and in large excess and applying the integrated rate law',
          value: 'catalystLargeXS',
        },
        {
          text: 'By holding the $\\ce{[H2O2]}$ constant and in large excess and applying the integrated rate law',
          value: 'H2O2LargeXS',
        },
      ],
      optionsTimeFrame: [
        {text: '0-30 s', value: '0to30'},
        {text: '0-60 s', value: '0to60'},
        {text: 'Over the entire 10 min', value: 'fullTime'},
        {text: '60-120 s', value: '60to120'},
        {text: 'In the last 1 min', value: 'lastMin'},
      ],
    };
  },
};
</script>
<style scoped></style>
